.activeNavLink .chakra-text {
  font-weight: 900;
}
.activeBreadcrumbLink {
  color: #1a202c !important;
}
html,
body {
  background-color: #f0f3f6 !important;
}

html,
body,
#root {
  height: 100vh;
}

.sideMenu a {
  color: #fff;
  transition: all 100ms ease-in-out;
}
.sideMenu .activeNavLink,
.sideMenu a:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.sideMenu a.subItem {
  padding: 10px 20px;
}

.sideMenu a.subItem.activeNavLink {
  font-weight: bold;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-bottom: 20px;
  width: 100%;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}

.chakra-accordion .chakra-accordion__item {
  border: none;
}

.chakra-accordion .chakra-accordion__item h2 {
  background-color: #efefef;
  border-radius: 10px;
  margin-top: 10px;
}

.chakra-accordion .chakra-accordion__item button {
  box-shadow: none;
  border-radius: 10px;
}

.chakra-form-control {
  align-self: flex-start;
}

.custom-tab-button {
  padding: 5px 5px 5px 15px;
  background-color: var(--chakra-colors-gray-100);
  border-radius: var(--chakra-radii-md);
  margin-bottom: 5px;
  line-height: 32px;
  cursor: pointer;
}

.custom-tab-button button {
  background-color: rgba(255, 255, 255, 0.9);
}

.custom-tab-button:hover {
  background-color: var(--chakra-colors-gray-200);
}

.custom-tab-active,
.custom-tab-active:hover {
  background-color: var(--chakra-colors-green-500);
  color: #fff;
}
